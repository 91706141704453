import React, { useEffect, useRef, useState } from 'react';

import styles from './styles.module.css';

import Portal from './portal';
import { withStyledComponents } from './with-styled-component';
import classNames from 'classnames';

type Position = {
  top: number;
  left: number;
  height: number;
  width: number;
};

const StyledTooltip = withStyledComponents(styles.StyledTooltip, 'span');

export const Tooltip = ({
  children,
  placement,
  title,
  show,
  disabled,
  offset,
  desktopOnly,
  inIframe,
  maxWidth,
}: {
  inIframe?: boolean;
  disabled?: boolean;
  children: JSX.Element;
  title: string;
  maxWidth?: number;
  show?: boolean;
  desktopOnly?: boolean;
  offset?: { top?: number; left?: number };
  placement?: 'top-right' | 'top-left' | 'right' | 'bottom' | 'top' | 'left' | 'bottom-left';
}) => {
  const [showTooltip, setShowTooltip] = useState(show || false);
  const [tooltipPos, setTooltipPos] = useState<Partial<Position>>({});
  const elRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (showTooltip) {
      const { top, height, width, left } = elRef.current?.getBoundingClientRect() || {};
      setTooltipPos({ top, height, width, left });
    }
  }, [showTooltip]);

  useEffect(() => {
    if (disabled) {
      setShowTooltip(false);
    }
  }, [disabled]);

  if (disabled) {
    return children;
  }

  const { top, left, height, width } = tooltipPos;

  const tooltip = (
    <StyledTooltip
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      data-tooltip={title}
      style={{
        top: top ? `${top + (offset?.top || 0)}px` : undefined,
        left: left ? `${left + (offset?.left || 0)}px` : undefined,
        height: height ? `${height}px` : undefined,
        width: width ? `${width}px` : undefined,
        maxWidth: maxWidth ? `${maxWidth}px` : undefined,
        whiteSpace: maxWidth ? 'normal' : undefined,
      }}
      className={classNames(
        placement === 'bottom-left'
          ? 'tooltip-bottom tooltip-bottom-left'
          : placement === 'top-right'
            ? 'tooltip-top tooltip-top-right'
            : placement === 'top-left'
              ? 'tooltip-top tooltip-top-left'
              : `tooltip-${placement || 'top'}`,
        { ['desktop-only']: desktopOnly },
      )}
    />
  );

  return (
    <>
      {React.cloneElement(children, {
        onMouseEnter: () => setShowTooltip(true),
        onMouseLeave: () => setShowTooltip(false),
        ref: elRef,
      })}

      {showTooltip && <Portal>{tooltip}</Portal>}
    </>
  );
};
